


<template>
    <div class="rightLink">
      <div class="bottomDiv">
        <div class="title">运营管理</div>

          </div>
        
      <!-- </div> -->
    </div>
  </template>
  <script>

  
  </script>
  <style scoped>

  </style>